import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
// import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function SwitchListSecondary() {
  const classes = useStyles();
  // const [checked, setChecked] = React.useState(['wifi']);
  const [data, setData] = React.useState(['-', '-', '-', '-']);
  const [isOn, setIsOn] = React.useState<boolean | null>(null);
  const [isSignBoxOn, setIsSignBoxOn] = React.useState<boolean | null>(null);
  const [isFrontLightOn, setIsFrontLightOn] = React.useState<boolean | null>(
    null
  );
  const [isUpStairLightOn, setIsUpStairLightOn] = React.useState<
    boolean | null
  >(null);
  const [isKitchenLightOn, setIsKitchenLightOn] = React.useState<
    boolean | null
  >(null);
  const [isBakingLightOn, setIsBakingLightOn] = React.useState<boolean | null>(
    null
  );
  const [isHardOn, setIsHardOn] = React.useState<boolean>(false);
  useEffect(() => {
    fetch(process.env.REACT_APP_API_DOMAIN + '/api/temp')
      .then(res => res.json())
      .then(sensor => {
        if (sensor) setData(sensor);
      });
    fetch(process.env.REACT_APP_API_DOMAIN + '/api/current')
      .then(res => res.json())
      .then(current => {
        if (current !== undefined) setIsOn(!!current);
      });
    fetch(process.env.REACT_APP_API_DOMAIN + '/api/dynamiccurrent/1/1')
      .then(res => res.json())
      .then(current => {
        if (current !== undefined) setIsFrontLightOn(!!current);
      });
    fetch(process.env.REACT_APP_API_DOMAIN + '/api/dynamiccurrent/1/2')
      .then(res => res.json())
      .then(current => {
        if (current !== undefined) setIsSignBoxOn(!!current);
      });
    fetch(process.env.REACT_APP_API_DOMAIN + '/api/dynamiccurrent/4')
      .then(res => res.json())
      .then(current => {
        if (current !== undefined) setIsKitchenLightOn(!!current);
      });
    fetch(process.env.REACT_APP_API_DOMAIN + '/api/dynamiccurrent/3')
      .then(res => res.json())
      .then(current => {
        if (current !== undefined) setIsUpStairLightOn(!!current);
      });
    fetch(process.env.REACT_APP_API_DOMAIN + '/api/dynamiccurrent/2')
      .then(res => res.json())
      .then(current => {
        if (current !== undefined) setIsBakingLightOn(!!current);
      });
    setInterval(() => {
      fetch(process.env.REACT_APP_API_DOMAIN + '/api/temp')
        .then(res => res.json())
        .then(sensor => {
          if (sensor) setData(sensor);
        });
      fetch(process.env.REACT_APP_API_DOMAIN + '/api/current')
        .then(res => res.json())
        .then(current => {
          if (current !== undefined) setIsOn(!!current);
        });
      fetch(process.env.REACT_APP_API_DOMAIN + '/api/dynamiccurrent/1/1')
        .then(res => res.json())
        .then(current => {
          if (current !== undefined) setIsFrontLightOn(!!current);
        });
      fetch(process.env.REACT_APP_API_DOMAIN + '/api/dynamiccurrent/1/2')
        .then(res => res.json())
        .then(current => {
          if (current !== undefined) setIsSignBoxOn(!!current);
        });
      fetch(process.env.REACT_APP_API_DOMAIN + '/api/dynamiccurrent/4')
        .then(res => res.json())
        .then(current => {
          if (current !== undefined) setIsKitchenLightOn(!!current);
        });
      fetch(process.env.REACT_APP_API_DOMAIN + '/api/dynamiccurrent/3')
        .then(res => res.json())
        .then(current => {
          if (current !== undefined) setIsUpStairLightOn(!!current);
        });
      fetch(process.env.REACT_APP_API_DOMAIN + '/api/dynamiccurrent/2')
        .then(res => res.json())
        .then(current => {
          if (current !== undefined) setIsBakingLightOn(!!current);
        });
    }, 2000);
  }, []);
  // const handleToggle = (value: string) => () => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }

  //   setChecked(newChecked);
  // };
  const [temp, humid] = data;
  const loading =
    isBakingLightOn === null ||
    isUpStairLightOn === null ||
    isKitchenLightOn === null ||
    isFrontLightOn === null ||
    isSignBoxOn === null ||
    isOn === null;
  // const lightStatusLabel = isOn ? "ON" : "OFF";
  return (
    <Container maxWidth="sm">
      <List
        subheader={<ListSubheader>Current room sensor data</ListSubheader>}
        className={classes.root}
      >
        <ListItem>
          {/* <ListItemIcon>
          <WifiIcon />
        </ListItemIcon> */}
          <ListItemText id="switch-list-label-wifi" primary="Temperature" />
          <ListItemSecondaryAction>{temp}</ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          {/* <ListItemIcon>
          <BluetoothIcon />
        </ListItemIcon> */}
          <ListItemText id="switch-list-label-bluetooth" primary="Humidity" />
          <ListItemSecondaryAction>{humid}</ListItemSecondaryAction>
        </ListItem>
      </List>
      <List
        subheader={<ListSubheader>Light</ListSubheader>}
        className={classes.root}
      >
        <ListItem>
          <ListItemText id="switch-list-label-bluetooth" primary="Sign Box" />
          <ListItemSecondaryAction>
            <FormControl component="fieldset">
              <FormGroup>
                <Switch
                  edge="end"
                  disabled={loading}
                  onChange={() => {
                    if (isSignBoxOn) {
                      fetch(
                        process.env.REACT_APP_API_DOMAIN +
                          '/api/dynamicswitchoff/1/2'
                      )
                        .then(res => res.json())
                        .then(current => {
                          setIsSignBoxOn(false);
                        });
                    } else
                      fetch(
                        process.env.REACT_APP_API_DOMAIN +
                          '/api/dynamicswitchon/1/2'
                      )
                        .then(res => res.json())
                        .then(current => {
                          setIsSignBoxOn(true);
                        });
                  }}
                  checked={!!isSignBoxOn}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-bluetooth',
                  }}
                />
              </FormGroup>
              {/* <FormHelperText>{`Currently: ${lightStatusLabel}`}</FormHelperText> */}
            </FormControl>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText id="switch-list-label-bluetooth" primary="Front" />
          <ListItemSecondaryAction>
            <FormControl component="fieldset">
              <FormGroup>
                <Switch
                  edge="end"
                  disabled={loading}
                  onChange={() => {
                    if (isFrontLightOn) {
                      fetch(
                        process.env.REACT_APP_API_DOMAIN +
                          '/api/dynamicswitchoff/1/1'
                      )
                        .then(res => res.json())
                        .then(current => {
                          setIsFrontLightOn(false);
                        });
                    } else
                      fetch(
                        process.env.REACT_APP_API_DOMAIN +
                          '/api/dynamicswitchon/1/1'
                      )
                        .then(res => res.json())
                        .then(current => {
                          setIsFrontLightOn(true);
                        });
                  }}
                  checked={!!isFrontLightOn}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-bluetooth',
                  }}
                />
              </FormGroup>
            </FormControl>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText id="switch-list-label-bluetooth" primary="Baking" />
          <ListItemSecondaryAction>
            <FormControl component="fieldset">
              <FormGroup>
                <Switch
                  edge="end"
                  disabled={loading}
                  onChange={() => {
                    if (isBakingLightOn) {
                      fetch(
                        process.env.REACT_APP_API_DOMAIN +
                          '/api/dynamicswitchoff/2'
                      )
                        .then(res => res.json())
                        .then(current => {
                          setIsBakingLightOn(false);
                        });
                    } else
                      fetch(
                        process.env.REACT_APP_API_DOMAIN +
                          '/api/dynamicswitchon/2'
                      )
                        .then(res => res.json())
                        .then(current => {
                          setIsBakingLightOn(true);
                        });
                  }}
                  checked={!!isBakingLightOn}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-bluetooth',
                  }}
                />
              </FormGroup>
            </FormControl>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText id="switch-list-label-bluetooth" primary="Kitchen" />
          <ListItemSecondaryAction>
            <FormControl component="fieldset">
              <FormGroup>
                <Switch
                  edge="end"
                  disabled={loading}
                  onChange={() => {
                    if (isKitchenLightOn) {
                      fetch(
                        process.env.REACT_APP_API_DOMAIN +
                          '/api/dynamicswitchoff/4'
                      )
                        .then(res => res.json())
                        .then(current => {
                          setIsKitchenLightOn(false);
                        });
                    } else
                      fetch(
                        process.env.REACT_APP_API_DOMAIN +
                          '/api/dynamicswitchon/4'
                      )
                        .then(res => res.json())
                        .then(current => {
                          setIsKitchenLightOn(true);
                        });
                  }}
                  checked={!!isKitchenLightOn}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-bluetooth',
                  }}
                />
              </FormGroup>
            </FormControl>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText id="switch-list-label-bluetooth" primary="Up stair" />
          <ListItemSecondaryAction>
            <FormControl component="fieldset">
              <FormGroup>
                <Switch
                  edge="end"
                  disabled={loading}
                  onChange={() => {
                    if (isUpStairLightOn) {
                      fetch(
                        process.env.REACT_APP_API_DOMAIN +
                          '/api/dynamicswitchoff/3'
                      )
                        .then(res => res.json())
                        .then(current => {
                          setIsUpStairLightOn(false);
                        });
                    } else
                      fetch(
                        process.env.REACT_APP_API_DOMAIN +
                          '/api/dynamicswitchon/3'
                      )
                        .then(res => res.json())
                        .then(current => {
                          setIsUpStairLightOn(true);
                        });
                  }}
                  checked={!!isUpStairLightOn}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-bluetooth',
                  }}
                />
              </FormGroup>
            </FormControl>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText id="switch-list-label-bluetooth" primary="Rom" />
          <ListItemSecondaryAction>
            <FormControl component="fieldset">
              <FormGroup>
                <Switch
                  edge="end"
                  disabled={loading}
                  onChange={() => {
                    if (isOn) {
                      fetch(process.env.REACT_APP_API_DOMAIN + '/api/switchoff')
                        .then(res => res.json())
                        .then(current => {
                          setIsOn(false);
                        });
                    } else
                      fetch(process.env.REACT_APP_API_DOMAIN + '/api/switchon')
                        .then(res => res.json())
                        .then(current => {
                          setIsOn(true);
                        });
                  }}
                  checked={!!isOn}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-bluetooth',
                  }}
                />
              </FormGroup>
              {/* <FormHelperText>{`Currently: ${lightStatusLabel}`}</FormHelperText> */}
            </FormControl>
          </ListItemSecondaryAction>
        </ListItem>
        {/* <ListItem>
          <ListItemText id="switch-list-label-bluetooth" primary="Auto" />
          <ListItemSecondaryAction>
            <FormControl component="fieldset">
            <FormGroup>
            <Switch
            edge="end"
            disabled={loading}
            onChange={() => {
              if (isHardOn) {
                fetch(process.env.REACT_APP_API_DOMAIN + "/api/autooff")
                        .then((res) => res.json())
                        .then((current) => {
                          setIsHardOn(false);
                        });
                      } else
                      fetch(process.env.REACT_APP_API_DOMAIN + "/api/autoon")
                      .then((res) => res.json())
                      .then((current) => {
                        setIsHardOn(true);
                      });
                    }}
                    checked={isHardOn}
                    inputProps={{
                      "aria-labelledby": "switch-list-label-bluetooth",
                  }}
                  />
                  </FormGroup>
                  </FormControl>
                  </ListItemSecondaryAction>
                </ListItem> */}
      </List>
      <List
        subheader={<ListSubheader>Air conditioner</ListSubheader>}
        className={classes.root}
      >
        <ListItem>
          <ListItemText id="switch-list-label-bluetooth" primary="On" />
          <ListItemSecondaryAction>
            <Button
              onClick={() => {
                fetch(process.env.REACT_APP_API_DOMAIN + '/api/turnonac');
              }}
              variant="contained"
              color="primary"
            >
              Turn on
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText id="switch-list-label-bluetooth" primary="Cold" />
          <ListItemSecondaryAction>
            <Button
              onClick={() => {
                fetch(process.env.REACT_APP_API_DOMAIN + '/api/turnaccold');
              }}
              variant="contained"
              color="primary"
            >
              Cold mode
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText id="switch-list-label-bluetooth" primary="Off" />
          <ListItemSecondaryAction>
            <Button
              onClick={() => {
                fetch(process.env.REACT_APP_API_DOMAIN + '/api/turnoffac');
              }}
              variant="contained"
              color="secondary"
            >
              Turn off
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <List
        subheader={<ListSubheader>Debug values</ListSubheader>}
        className={classes.root}
      >
        <ListItem>
          <ListItemText
            id="switch-list-label-bluetooth"
            primary="PIR"
            secondary={data[2]}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            id="switch-list-label-bluetooth"
            primary="HARD_OFF"
            secondary={data[3]}
          />
        </ListItem>
      </List>
    </Container>
  );
}
